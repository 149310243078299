import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "./filter"
import { getCSATReport, getCSATReportData, getSurveyReport } from "../../store/reports/reportReducer"
import { DownloadDocumentReport } from "../../download"


export const SurveyReport = (props) => {

  const dispatch = useDispatch()

  const [filters, setFilters] = useState()

  const get = () => {
    dispatch(getSurveyReport(filters))
  }

  const { loading, data ,download} = useSelector((state) => ({
    loading: state.Reports.loading,
    data: state.Reports.survey,
  }))


  useEffect(() => {
    !loading && get()
  }, [filters])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Card>
          <CardBody>

            <DownloadDocumentReport className="float-end" url={`/app/reports/download/survey`} filters={filters}
                                    title={"Survey Report"} />
            <CardTitle>Survey Report</CardTitle>
            <hr />
            <ReviewFilters size={4} branch date department onChange={setFilters} />

          </CardBody>

        </Card>
      </Container>
    </div>
  </React.Fragment>
}



