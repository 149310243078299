import React from "react"
import { Card, CardBody, Table } from "reactstrap"

export default class FeedbackFormFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feedback_form_fields: this.props.feedback_form_fields
    }
  }

  render() {
    const { feedback_form_fields } = this.state
    const setField = (index, field, value) => {
      let { feedback_form_fields } = this.state
      feedback_form_fields[index][field] = value
      this.setState({ feedback_form_fields }, () => this.props.onChange(feedback_form_fields))
    }
    return <>
      <Table>
        <thead>
        <tr>
          <th>Label</th>
          <th>Mandatory</th>
          <th>Hide</th>
        </tr>
        </thead>
        <tbody>
        {feedback_form_fields && typeof feedback_form_fields === "object" && feedback_form_fields.map((row, index) => {
          const id = `field-${row.id}`
          return <tr key={id}>
            <th style={{ width: "50%" }}>{row.label}</th>
            <td className="text-center">
              <div className="form-check form-switch ">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={id}
                  value={row.mandatory}
                  onClick={e => {
                    setField(index, "mandatory", row.mandatory === "yes" ? "no" : "yes")
                  }}
                  checked={row.mandatory === "yes"}
                />
              </div>
            </td>
            <td className="text-center">
              <div className="form-check form-switch ">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={id}
                  value={row.hidden}
                  onClick={e => {
                    setField(index, "hidden", row.hidden === "yes" ? "no" : "yes")
                  }}
                  checked={row.hidden === "yes"}
                />

              </div>
            </td>
          </tr>

        })}
        </tbody>
      </Table>
    </>

  }

}
