import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
import axios from "axios"
// Import all middleware
import AuthMiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

axios.defaults.baseURL = "https://sonarapi.qsys-ea.com/api/"

axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.post.Accept = "application/json"
axios.defaults.headers.get.Accept = "application/json"
// axios.defaults.headers.common = {'Authorization': `Bearer 5|MIQLZG4fwJCxwfsc9iAY3uoA5aypdkOtgJhejAvKcdb87c9e`};

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { checkLoggedIn } from "./store/authentication/authenticationActions"
import Pages404 from "./pages/Utility/pages-404"
import PageAuth from "./pages/Utility/pages-auth"
import { Preview } from "./application/feedback/preview"
import { SYSDashboard } from "./application/dashboard/daashboard"
import { FormFinal } from "./application/feedback/FormFinal"

// Activating fake backend
// fakeBackend();
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCMkwU3PEsWmmmflSPkw3vfU1glX-spVoU",
//   authDomain: "qsys-feedback.firebaseapp.com",
//   projectId: "qsys-feedback",
//   storageBucket: "qsys-feedback.appspot.com",
//   messagingSenderId: "38699857301",
//   appId: "1:38699857301:web:cd235bc31b2c55208d729c"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = (layoutType) => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType
  }))

  const { checking, loggedIn, loading } = useSelector((state) => ({
    loading: state.Authentication.loading,
    checking: state.Authentication.checking,
    loggedIn: state.Authentication.loggedIn
  }))


  const Layout = getLayout(layoutType)
  const dispatch = useDispatch()
  useEffect(() => dispatch(checkLoggedIn()), [])

  return (
    <React.Fragment>
      <Routes>
        {/*{!checking && !loggedIn && }*/}
        <Route path={"/form/:id"} exact={true} element={<NonAuthLayout><Preview /></NonAuthLayout>} />
        <Route path={"/form2/:id"} exact={true} element={<NonAuthLayout><FormFinal /></NonAuthLayout>} />

        {!checking && !loggedIn && publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {!checking && loggedIn && authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            route={route}
            element={
              //
              <AuthMiddleware> {/*Authmiddleware*/}
                <Layout>{route.component}</Layout>
              </AuthMiddleware>}
            key={idx}
            exact={true}
          />
        ))}
        {checking && !loggedIn && <Route path={"*"} element={<NonAuthLayout><PageAuth /></NonAuthLayout>} />}

      </Routes>

    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App
