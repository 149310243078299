import React, { useEffect, useState } from "react"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap"
import {
  CommentBox,
  RatingQualitativeAgree,
  RatingQualitativeExcellence,
  RatingSmiley,
  RatingStar
} from "./inputOptions"
import { Link } from "react-router-dom"


export class SingleChoiceInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      choices: this.props.question.choices, response: ""
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.response !== prevState.response) {
      const { question, isFeedback } = this.props
      const { response } = this.state

      let answer = {}
      answer[question.id] = { response }
      this.props.appendResponse && this.props.appendResponse(answer)

    }
  }


  render() {
    const { response } = this.state
    const { question, isFeedback } = this.props


    return <React.Fragment>
      {question.choices && question.choices.map((choice, index) => choice.choice &&
        <div className="form-check form-check-inline mb-3" key={choice.id}>
          <>
            <input
              checked={choice.choice === response}
              onClick={() => {
                this.setState({ response: choice.choice })
              }}
              type={"radio"}
              id={`choice-${choice.id}`}
              name={`Question_${choice.question_id}`}
              className="form-check-input"
            />
            <label
              className="form-check-label"
              htmlFor={`choice-${choice.id}`}
            >
              {choice.choice}
            </label>
          </>
        </div>)}

    </React.Fragment>

  }

}

export class MultiChoicesInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      choices: this.props.question.choices
    }
  }


  update() {

    const { question } = this.props
    const { choices } = this.state

    let answer = {}
    answer[question.id] = { response: choices.filter(row => row.checked) }

    this.props.appendResponse && this.props.appendResponse(answer)


  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }


  render() {
    const { response, choices } = this.state
    const { question, isFeedback } = this.props


    const inputType = () => {
      //(isFeedback ? question.response_type : question.survey_response_type) === "Multi Choice" ? "checkbox" : "radio"
      // if(!isFeedback) {return "radio"}
      if (question.response_type === "Multi Choice") return "checkbox"
      if (question.response_type === "Single Choice") return "radio"
      return "radio"
    }

    return <React.Fragment>

      {question.choices && question.choices.map((choice, index) => choice.choice &&
        <div className="form-check form-check-inline  mb-3"
             key={choice.id}>
          <>
            <input
              checked={choices[index].checked}
              onClick={() => {
                let { choices } = this.state
                choices[index].checked = !choice.checked
                this.setState({ choices }, this.update)
              }}
              type={"checkbox"}
              id={`choice-${choice.id}`}
              name={`Question_${choice.question_id}`}
              className="form-check-input "
            />
            <label
              className="form-check-label "
              htmlFor={`choice-${choice.id}`}
            >

              {choice.choice ? choice.choice : "Undefined"}
            </label>
          </>
        </div>)}

    </React.Fragment>

  }

}

export const NormalQuestion = ({ question, appendResponse }) => {

  const [response, setResponse] = useState("")


  const translateResponse = () => {

    const agree = ["Strongly Disagree", "Disagree", "Neither Agree nor Disagree", "Agree", "Strongly Agree"]
    const excellence = ["Very Poor", "Poor", "Good", "Very Good", "Excellent"]

    switch (question.response_type) {

      case "Smiley Rating (1-5)" : {
        return response > 0 ? `(${response}) Smiley faces` : "No response"
      }
      case "Star Rating (1-5)" : {
        return response > 0 ? `(${response}) Star Rating` : "No Response"
      }
      case "Qualitative Options 1 (Very Poor - Excellent)" : {
        return response > 0 ? excellence[(response - 1 > 0) ? response - 1 : response] : "No Response"
      }
      case "Qualitative Options 2 (Strongly Disagree - Strongly Agree)" : {
        return response > 0 ? agree[(response - 1 > 0) ? response - 1 : response] : "No Response"
      }
      default : {
        return response ? response : "No Response"
      }

    }

  }
  const [complain, setComplain] = useState("")

  useEffect(() => {

    let answer = {}
    answer[question.id] = { response: translateResponse(response), complain }
    appendResponse && appendResponse(answer)
  }, [response])

  if (!["Normal"].includes(question.question_type)) return <></>


  const onChange = (value) => {
    if (value < 3) toggle()
    setResponse(value)
  }

  const [form, setForm] = useState(false)

  const toggle = () => setForm(!form)

  return <>
    {question.response_type === "Comment" &&
      <CommentBox onChange={onChange} value={response} />}
    {question.response_type === "Smiley Rating (1-5)" &&
      <RatingSmiley onChange={onChange} value={response} />}
    {question.response_type === "Star Rating (1-5)" &&
      <RatingStar onChange={onChange} value={response} />}
    {question.response_type === "Qualitative Options 1 (Very Poor - Excellent)" &&

      <RatingQualitativeExcellence {...question} value={response} onChange={onChange} />}
    {question.response_type === "Qualitative Options 2 (Strongly Disagree - Strongly Agree)" &&
      <RatingQualitativeAgree {...question} value={response} onChange={onChange} />}

    <Modal isOpen={form} toggle={toggle}>
      <ModalHeader>
        Kindly let us know why you gave this rating of [{response}]
      </ModalHeader>
      <ModalBody>

        <Input rows={2} type="textarea" value={complain} placeholder="Your Reasons"
               onChange={e => setComplain(e.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button className="btn-soft-primary" onClick={toggle}>Submit</Button>
      </ModalFooter>
    </Modal>


  </>

}


const CSATQuestion = ({ question, isFeedback, appendResponse }) => {

  const [response, setResponse] = useState(0)
  const [complain, setComplain] = useState("")
  const [form, setForm] = useState(false)

  const toggle = () => setForm(!form)

  const onChange = (value) => {
    setResponse(value)
    if (value < 3) toggle()
  }

  useEffect(() => {
    let answer = {}
    answer[question.id] = { response, complain }
    appendResponse && appendResponse(answer)
  }, [response, complain])

  if (question.question_type !== "CSAT") return <></>


  return <React.Fragment>
    {question.response_type === "Smiley Rating (1-5)" && <RatingSmiley onChange={onChange} value={response} />}
    {question.response_type === "Star Rating (1-5)" && <RatingStar onChange={onChange} value={response} />}
    {question.response_type === "Qualitative Options 1 (Very Poor - Excellent)" &&
      <RatingQualitativeExcellence {...question} value={response} onChange={onChange} />}
    {question.response_type === "Qualitative Options 2 (Strongly Disagree - Strongly Agree)" &&
      <RatingQualitativeAgree {...question} value={response} onChange={onChange} />}

    <Modal isOpen={form} toggle={toggle}>
      <ModalHeader>
        Kindly let us know why you gave this rating of [{response}]
      </ModalHeader>
      <ModalBody>

        <Input rows={2} type="textarea" value={complain} placeholder="Your Reasons"
               onChange={e => setComplain(e.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button className="btn-soft-primary" onClick={toggle}>Submit</Button>
      </ModalFooter>
    </Modal>
  </React.Fragment>
}

const NPSSlider = ({ question, appendResponse }) => {

  const [response, setResponse] = useState(0)
  if (question.question_type !== "NPS") return <></>
  const [temp, setTemp] = useState({})
  // const {}
  const [complain, setComplain] = useState("")

  const [form, setForm] = useState(false)
  const toggle = () => setForm(!form)

  useEffect(() => {
    let answer = {}
    answer[question.id] = { response, complain }
    setTemp(answer)
    appendResponse && appendResponse(answer)
  }, [response, complain])


  return <React.Fragment>

    {/*<div className="gradient_background" style={{*/}

    {/*     margin: 10}}>*/}
    {/*  {[0,1,2,3,4,5,6,7,8,9,10].map(row => <div key={row}*/}
    {/*                                            on={() => console.log(row)}*/}
    {/*                                            className="gradient_button "*/}

    {/*  >{row}</div>)}*/}

    {/*</div>*/}


    <div>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(row => response === row  ? <span key={row} style={{
        display: "inline-block",
        width: "8.8%",
        borderRadius: "10px",
        marginRight: "0.1%",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: row < 7 ? "red" : "green"
      }}><i className="mdi mdi-star text-warning font-size-14 "></i></span> : <Link style={{
        display: "inline-block",
        width: "8.8%",
        borderRadius: "10px",
        marginRight: "0.1%",
        backgroundColor: "#e9e9e9",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
      }} key={row} onClick={() => {
        setResponse(row)
        if (row <= 6) {
          toggle()
        }
      }}><strong style={{ color: "black" }}>{row}</strong></Link>)}
    </div>

    {/*<Pagination*/}
    {/*  aria-label="Page navigation example"*/}
    {/*  listClassName=""*/}
    {/*>*/}
    {/*  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(row => <PaginationItem style={{ margin: 1 }} active={response >= row}*/}
    {/*                                                                 className="bg-primary" key={`${row}`}>*/}
    {/*    <PaginationLink onClick={() => {*/}
    {/*      setResponse(row)*/}
    {/*      if (row <= 6) {*/}
    {/*        toggle()*/}
    {/*      }*/}
    {/*    }}*/}
    {/*                    style={{ paddingLeft: 20, paddingRight: 20 }}>{row}</PaginationLink>*/}
    {/*  </PaginationItem>)}*/}


    {/*</Pagination>*/}
    <Modal isOpen={form} toggle={toggle}>
      <ModalHeader>
        Kindly let us know why you gave this rating of [{response}]
      </ModalHeader>
      <ModalBody>
        <Input rows={2} type="textarea" value={complain}
               placeholder="Your Reasons"
               onChange={e => setComplain(e.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button className="btn-soft-primary" onClick={toggle}>Submit</Button>
      </ModalFooter>
    </Modal>
  </React.Fragment>
}


export const QuestionPreview = ({ question, isFeedback, appendResponse }) => {


  return <div className="p-5 shadow-sm mb-2" style={{backgroundColor: "rgba(255,255,255,0.9)", borderRadius: "40px"}}>

    <h5 className="">{question.question}  </h5>


    <div className="p-3">

      {(question.question_type === "Survey" && ["Multi Choice"].includes(isFeedback ? question.response_type : question.survey_response_type)) &&
        <MultiChoicesInput question={question} isFeedback={isFeedback} appendResponse={appendResponse} />

      }
      {(question.question_type === "Survey" && ["Single Choice"].includes(isFeedback ? question.response_type : question.survey_response_type)) &&
        <SingleChoiceInput question={question} isFeedback={isFeedback} appendResponse={appendResponse} />}

      <CSATQuestion question={question} isFeedback={isFeedback} appendResponse={appendResponse} />
      <NormalQuestion question={question} appendResponse={appendResponse} />
      <NPSSlider question={question} appendResponse={appendResponse} />


    </div>

  </div>
}
