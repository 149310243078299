import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Table
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useNavigate } from "react-router-dom"
import UserForm from "./forms/userForm"
import { canActivateUser, canBlockUser, canDeactivateUser, canDeleteUser, canEditUser } from "../permissions"
import { userStatus } from "../../components/status"
import { userFind, userPost, userActivation, userBlock, userDelete } from "../../store/users/userActions"
import { Avatar } from "../../components/Common/Avatar"
import ComplaintReportChart from "../reports/charts/complaintReports"
import ResetPasswordForm from "./forms/resetPasswordForm"


export const UserDetails = (props) => {
  const navigate = useNavigate();

  const { id } = useParams()
  const dispatch = useDispatch()
  const { loading, user, permissions } = useSelector(state => ({
    user: state.Users.user,
    loading: state.Users.loading,
    permissions: state.Permissions.permissions
  }))

  const [form, setForm] = useState(false)
  const [reset, setReset] = useState(false)

  const toggle = () => setForm(!form)

  document.title = `${user?.name ? user?.name : (loading ? "loading" : user?.name)} | Users | CMS`

  const get = () => dispatch(userFind(id))


  const p_EDIT = true || ((permissions || []).filter(({ name }) => name === "EDIT USER").length > 0)
  const p_DEACTIVATE = true || ((permissions || []).filter(({ name }) => name === "DEACTIVATE USER").length > 0)
  const p_ACTIVATE = true || ((permissions || []).filter(({ name }) => name === "ACTIVATE USER").length > 0)
  const p_BLOCK = true || ((permissions || []).filter(({ name }) => name === "BLOCK USER").length > 0)
  const p_DELETE = true || ((permissions || []).filter(({ name }) => name === "DELETE USER").length > 0)


  useEffect(() => get(), [id])


  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : user?.name}
          breadcrumbItem={"Settings"}
        />
        <Row>
          <Col xl="4">
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col xs="7">
                    <div className="text-primary p-3">
                      <h5 className="text-primary">Welcome</h5>
                      {/*<p>It will seem like simplified</p>*/}
                    </div>
                  </Col>
                  <Col xs="5" className="align-self-end p-5 ">
                    {/*<img src={profile1} alt="" className="img-fluid" />*/}
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <Row>
                  <Col sm={3}>
                    <div className="avatar-md text-center profile-user-wid mb-4">

                      <Avatar loading={loading} user={user} className="avatar-md" />
                      {user && <Badge className="mt-1 p-1 font-size-13" color={userStatus[user.status].color}><i
                        className={userStatus[user.status].icon} /> {userStatus[user.status].title}</Badge>}

                    </div>
                  </Col>
                  <Col sm={9}>
                    <h5 className="font-size-15 mt-2 text-truncate">
                      {user?.name}
                    </h5>
                    <p className="text-muted mb-0">
                      {user?.email}
                    </p>

                    <div className="pt-4 " hidden>
                      <Row>
                        <Col xs="6">
                          <h5 className="font-size-24">
                            {(user.roles || []).length}
                          </h5>
                          <p className="text-muted mb-0">Roles</p>
                        </Col>
                        <Col xs="6">
                          <h5 className="font-size-24">
                            {/*{(user.companies || []).length}*/}
                          </h5>
                          <p className="text-muted mb-0">Companies</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <ButtonGroup block className="mt-1">
                  {(canEditUser(user) && p_EDIT) &&
                    <Button onClick={toggle} to="" className="btn btn-soft-primary">
                      Edit
                      <i className="mdi mdi-account-edit-outline ms-1" />
                    </Button>}
                  {(canEditUser(user) && p_EDIT) &&
                    <Button onClick={() => setReset(true)} to="" className="btn btn-soft-danger">
                      Reset Password
                      <i className="mdi mdi-account-key-outline ms-1" />
                    </Button>}
                  {(canDeactivateUser(user) && p_DEACTIVATE) &&
                    <Button onClick={() => dispatch(userActivation(user.id))} to="" className="btn btn-soft-warning">
                      Deactivate
                      <i className="mdi mdi-account-cancel-outline ms-1" />
                    </Button>}
                  {(canActivateUser(user) && p_ACTIVATE) &&
                    <Button onClick={() => dispatch(userActivation(user.id))} to="" className="btn btn-soft-success">
                      Activate
                      <i className="mdi mdi-account-check-outline ms-1" />
                    </Button>}
                  {(canBlockUser(user) && p_BLOCK) &&
                    <Button onClick={() => dispatch(userBlock(user.id))} to="" className="btn btn-soft-danger">
                      Block
                      <i className="mdi mdi-account-cancel ms-1" />
                    </Button>}
                  {(canDeleteUser(user) && p_DELETE) &&
                    <Button onClick={() => dispatch(userDelete(user.id, navigate(`/admin/users`)))} to=""
                            className="btn btn-danger">
                      Delete
                      <i className="mdi mdi-account-cancel ms-1" />
                    </Button>}
                </ButtonGroup>
              </CardBody>
            </Card>
            <Card>

              <CardBody> <CardTitle className="mb-4">Roles</CardTitle>
                <ListGroup>
                  {(user.roles || []).map(row => <ListGroupItem key={row.id}>

                    <Link to={`/settings/roles/${row.id}`}>
                      <strong className="text-muted">{row.name}</strong>
                    </Link>
                  </ListGroupItem>)}
                </ListGroup>
              </CardBody>
            </Card>

          </Col>
          <Col xl="8">
            {user && <Row>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium mb-2">Forms </p>
                        <h4
                          className="mb-0">{((user?.contracts || []).filter(({ verification_status }) => ["pending", "rejected"].includes(verification_status))).length}</h4>
                      </div>
                      <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                        <span className="avatar-title bg-danger">
                          <i className="mdi mdi-alert-rhombus-outline font-size-24" />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium mb-2">Open Tickets</p>
                        <h4 className="mb-0">
                          {((user?.assigned_to || []).filter(({ status }) =>( status === "open" || status === 'in progress'))).length}
                        </h4>
                      </div>
                      <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-warning">
                        <span className="avatar-title bg-warning">
                          <i className={"bx bx-hourglass font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium mb-2">Resolved Tickets</p>
                        <h4 className="mb-0">
                          {((user?.assigned_to || []).filter(({ status }) => status === "closed")).length}
                        </h4>
                      </div>
                      <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-success">
                        <span className="avatar-title bg-success">
                          <i className={"bx mdi mdi-handshake-outline font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>}
            <ComplaintReportChart dataColors='["--bs-danger","--bs-success"]' />


          </Col>
        </Row>
        <ResetPasswordForm toggle={() => setReset(!reset)} user={user.id} isOpen={reset}
                           callBack={() => setReset(false)} />

        <UserForm toggle={toggle}
                  sourceLoading={loading}
                  isOpen={form}
                  user={user.id}
                  saveChanges={data => {
                    setForm(false)
                    dispatch(userPost(data, get))
                  }} />
      </Container>
    </div>
  </React.Fragment>

}
